<template>
  <div class="itemizes_query_wrapper container">
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="查企业" name="查企业">
        <QueryEnterprise />
      </el-tab-pane>

      <el-tab-pane label="查业绩" name="查业绩">
        <QueryPerformance />
      </el-tab-pane>

      <el-tab-pane label="查资质" name="查资质">
        <QueryCertification />
      </el-tab-pane>

      <el-tab-pane label="查项目经理" name="项目经理">
        <QueryProjectManager />
      </el-tab-pane>

      <el-tab-pane label="查技术负责人" name="技术负责人">
        <QueryTechnicalDirector />
      </el-tab-pane>

      <el-tab-pane label="查人员" name="查人员">
        <QueryPersonnel />
      </el-tab-pane>

      <el-tab-pane label="查招标" name="查招标">
        <QueryBids />
      </el-tab-pane>

      <el-tab-pane label="查荣誉" name="查荣誉">
        <QueryHonor />
      </el-tab-pane>

      <el-tab-pane label="查诚信" name="查诚信">
        <QueryIntegrity />
      </el-tab-pane>

      <el-tab-pane label="查评价" name="查评价">
        <QueryEvaluate />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/* 分享查询 */
// 组件
import QueryEnterprise from "@/views/itemizedQuery/subpage/query-enterprise.vue";
import QueryPerformance from "@/views/itemizedQuery/subpage/query-performance.vue";
import QueryPersonnel from "@/views/itemizedQuery/subpage/query-personnel.vue";
import QueryProjectManager from "@/views/itemizedQuery/subpage/query-project-manager.vue";
import QueryTechnicalDirector from "@/views/itemizedQuery/subpage/query-technical-director.vue";
import QueryCertification from "@/views/itemizedQuery/subpage/query-certification.vue";
import QueryHonor from "@/views/itemizedQuery/subpage/query-honor.vue";
import QueryIntegrity from "@/views/itemizedQuery/subpage/query-integrity.vue";
import QueryBids from "@/views/itemizedQuery/subpage/query-bids.vue";
import QueryEvaluate from "@/views/itemizedQuery/subpage/query-evaluate.vue";

export default {
  components: {
    QueryEnterprise,
    QueryPerformance,
    QueryPersonnel,
    QueryProjectManager,
    QueryTechnicalDirector,
    QueryCertification,
    QueryHonor,
    QueryIntegrity,
    QueryBids,
    QueryEvaluate,
  },
  // 数据
  data() {
    return {
      activeName: "查企业",
    };
  },

  // 方法
  methods: {
    /* tab的click事件 */
    handleTabClick(tab) {
      this.activeName = tab.name;
      this.$router.push({ name: "itemizedQuery", params: { name: tab.name } });
    },
  },

  watch: {
    // 监听,当路由发生变化的时候执行
    $route: {
      // $route可以用引号，也可以不用引号
      handler(to, from) {
        let name = this.$route.params.name;
        if (name != "分项查询") {
          this.activeName = name;
        } else {
          this.$router.push({
            name: "itemizedQuery",
            params: { name: "查企业" },
          });
        }
      },
      deep: true, // 深度观察监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },

  // 生命周期-实例创建完成后
  async created() {},
};
</script>

<style lang="less" scoped>
.itemizes_query_wrapper {
  padding: 10px 0px 40px;

  /deep/.el-pagination {
    text-align: right;
    padding: 20px 30px;
  }

  /deep/.el-tabs__header {
    background-color: #fff;
    padding: 20px;
  }

  /deep/.el-form {
    background-color: #fff;
    margin-bottom: 30px;
    padding: 30px;
    padding-left: 20px;
  }

  /deep/.el-cascader,
  /deep/.el-date-editor {
    width: 100%;
  }

  /deep/.list {
    font-size: 16px;
    background-color: #fff;
    .desc{
      font-size: 14px;
    }
  }

  /deep/.total {
    padding: 20px 30px;
  }
}
</style>
