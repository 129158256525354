<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="企业名称">
            <el-input
              v-model.trim="form.companyName"
              placeholder="请输入企业名称进行搜索"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="成立时间">
            <el-date-picker
              v-model="time1"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="到期时间">
            <el-date-picker
              v-model="time2"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions1"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="showmore">
        <el-form-item label="资质分类" style="margin-bottom: 0px">
          <el-row
            class="mb22"
            v-for="(item, index) in certType"
            :key="'project' + index"
          >
            <el-col class="mr20" :span="8">
              <el-cascader
                v-model="item.value"
                :options="certTypeOptions"
                filterable
                :props="{
                  value: 'allDesc',
                  label: 'desc',
                  children: 'children',
                  emitPath: false,
                }"
                clearable
              ></el-cascader>
            </el-col>

            <el-col :span="2" class="tac">
              <el-button
                v-if="index == 0"
                type="primary"
                icon="el-icon-plus"
                plain
                @click="addCert"
              >
                增加
              </el-button>

              <el-button
                v-else
                @click="delCert(index)"
                type="danger"
                icon="el-icon-delete"
                circle
                plain
              ></el-button>
            </el-col>

            <el-col :span="8" class="tac" v-if="index == 0">
              <el-radio-group v-model="form.subsection">
                <el-radio label="同时具备"></el-radio>
                <el-radio label="任意均可"></el-radio>
                <el-radio label="有且仅有"></el-radio>
              </el-radio-group>
            </el-col>

            <el-col :span="4" class="tac" v-if="index == 0">
              <el-checkbox v-model="form.isExpire">包含资质到期</el-checkbox>
            </el-col>
          </el-row>
        </el-form-item>
      </div>

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>

          <el-button @click="showmore = !showmore">
            {{ this.showmore ? "收起" : "更多" }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        共为您找到
        <span class="highlight">{{ total }}</span>
        家符合的企业
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="name link" @click="toDetail(item.companyId)">
            {{ item.companyName | noVoid }}
          </div>

          <div class="desc">
            <div class="desc_item">
              资质数量：
              <span class="highlight2">{{ item.certNum || "0" }}</span>
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查资质 */
// 引入ajax函数
import { searchCert } from "@/api/itemizedQuery";
import { sourceTree } from "@/api/common";
import area from "@/utils/area"; // 引入区域json
import local from "@/utils/local";

export default {
  // 数据
  data() {
    return {
      form: {
        location: [], // 地区
        certType: "", // 资质分类
        isExpire: true, // 是否到期
        subsection: "同时具备", // 筛选条件
        createTimeMin: "", // 成立时间-开始
        createTimeMax: "", // 成立时间-结束
        endTimeMin: "", // 到期时间-开始
        endTimeMax: "", // 到期时间-结束
        companyName: "", // 企业名称
        userId: "",
        pageNum: 1,
        pageSize: 20,
      },

      areaOptions: [], // 地区选项数据
      time1: [], // 成立时间
      time2: [], // 到期时间
      certTypeOptions: [], // 资质分类选项数据
      certType: [{ value: "" }], // 资质分类

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      pickerOptions1: {
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const location = this.form.location.filter((v) => v != "").join("-");
      let createTimeMin = ""; // 成立时间-开始
      let createTimeMax = ""; // 成立时间-结束
      let endTimeMin = ""; // 到期时间-开始
      let endTimeMax = ""; // 到期时间-结束
      if (this.time1.length > 0) {
        createTimeMin = this.time1[0];
        createTimeMax = this.time1[1];
      }
      if (this.time2.length > 0) {
        endTimeMin = this.time2[0];
        endTimeMax = this.time2[1];
      }
      const certType = this.certType
        .filter((v) => v.value != "")
        .map((v) => v.value)
        .join(",");

      const params = {
        ...this.form,
        location,
        createTimeMin,
        createTimeMax,
        endTimeMin,
        endTimeMax,
        certType,
      };

      const res = await searchCert(params);
      this.list = res.records;
      this.total = res.total;
      this.loading = false;
    },
    // 获取树形数据
    async getOptions() {
      const certTypeOptions = await sourceTree({ source: "certType" });
      this.certTypeOptions = certTypeOptions;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        name: "certificationDetails",
        params: { id },
      });
    },
    // 添加资质分类
    addCert() {
      if (this.certType.length < 5) {
        this.certType.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除资质分类
    delCert(index) {
      this.certType.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        location: [], // 地区
        certType: "", // 资质分类
        isExpire: true, // 是否到期
        subsection: "同时具备", // 筛选条件
        createTimeMin: "", // 成立时间-开始
        createTimeMax: "", // 成立时间-结束
        endTimeMin: "", // 到期时间-开始
        endTimeMax: "", // 到期时间-结束
        companyName: "", // 企业名称
        userId: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.time1 = [];
      this.time2 = [];
      this.certType = [
        {
          value: "",
        },
      ];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },
    // 初始化
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      if (name) {
        this.form.companyName = name;
      }

      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例创建完成后
  async created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
